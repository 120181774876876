import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabaseClient } from '../context/supabaseContext';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../config';

export interface HubspotStage {
  id: string;
  visible: boolean;
}

export interface HubspotStages {
  [stageName: string]: HubspotStage;
}

export interface HubspotPipeline {
  id: string;
  label: string;
  stages: {
    id: string;
    label: string;
  }[];
}

interface HubspotContextType {
  isHubspotConnected: boolean;
  setIsHubspotConnected: (value: boolean) => void;
  handleConnectHubspot: (apiKey: string) => Promise<void>;
  handleDisconnectHubspot: () => Promise<void>;
  hubspotPipelines: HubspotPipeline[];
  selectedPipelineId: string;
  selectedStages: HubspotStages;
  isLoadingPipelines: boolean;
  setSelectedPipelineId: (id: string) => void;
  setSelectedStages: (stages: HubspotStages) => void;
  fetchHubspotPipelines: () => Promise<void>;
  toggleStageVisibility: (stageName: string) => void;
  saveHubspotSettings: () => Promise<void>;
  isSourceEnabled: boolean;
  setIsSourceEnabled: (enabled: boolean) => void;
}

const HubspotContext = createContext<HubspotContextType | undefined>(undefined);

export const HubspotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const [isHubspotConnected, setIsHubspotConnected] = useState(false);
  const [hubspotPipelines, setHubspotPipelines] = useState<HubspotPipeline[]>([]);
  const [selectedPipelineId, setSelectedPipelineId] = useState<string>('');
  const [selectedStages, setSelectedStages] = useState<HubspotStages>({});
  const [isLoadingPipelines, setIsLoadingPipelines] = useState(false);
  const [isSourceEnabled, setIsSourceEnabled] = useState(false);

  const handleConnectHubspot = async (apiKey: string) => {
    if (!user?.id) {
      throw new Error('User ID not available');
    }

    try {
      const { error } = await client
        .from('hubspot_access')
        .upsert({ 
          user_id: user.id,
          access_token: apiKey
        });

      if (error) throw error;

      setIsHubspotConnected(true);
    } catch (error) {
      console.error('Error connecting Hubspot:', error);
      throw error;
    }
  };

  const handleDisconnectHubspot = async () => {
    if (!user?.id) return;
    
    try {
      const { error } = await client
        .from('hubspot_access')
        .delete()
        .eq('user_id', user.id);

      if (error) throw error;

      setIsHubspotConnected(false);
      setSelectedPipelineId('');
      setSelectedStages({});
    } catch (error) {
      console.error('Error disconnecting Hubspot:', error);
      throw error;
    }
  };

  const fetchHubspotPipelines = async () => {
    setIsLoadingPipelines(true);
    try {
      console.log('Starting pipeline fetch...');
      const token = await getToken({ template: "supabase" });

      const response = await fetch(`${API_URL}/hubspot/pipelines`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch pipelines');
      }

      const data = await response.json();
      console.log('Retrieved pipelines:', data.results);
      setHubspotPipelines(data.results);

      // Get existing configuration
      const { data: hubspotData } = await client
        .from('hubspot_access')
        .select('hubspot_pipeline, hubspot_stages')
        .eq('user_id', user?.id)
        .single();

      // Set pipeline ID (use saved or default to first)
      const pipelineId = hubspotData?.hubspot_pipeline || (data.results.length > 0 ? data.results[0].id : '');
      setSelectedPipelineId(pipelineId);

      // Set stages (use saved or create default)
      let stages: HubspotStages = {};
      if (hubspotData?.hubspot_stages) {
        stages = hubspotData.hubspot_stages;
      } else if (data.results.length > 0) {
        const defaultPipeline = data.results.find((p: { id: string }) => p.id === pipelineId);
        defaultPipeline?.stages.forEach((stage: { id: string, label: string }) => {
          stages[stage.label] = {
            id: stage.id,
            visible: true
          };
        });
      }
      setSelectedStages(stages);

      // If this is a new connection (no existing pipeline), save the defaults
      if (!hubspotData?.hubspot_pipeline && pipelineId) {
        const { error } = await client
          .from('hubspot_access')
          .update({
            hubspot_pipeline: pipelineId,
            hubspot_stages: stages
          })
          .eq('user_id', user?.id);

        if (error) {
          console.error('Error saving default pipeline configuration:', error);
        } else {
          console.log('Saved default pipeline configuration:', { pipelineId, stages });
        }
      }

    } catch (error) {
      console.error('Error fetching Hubspot pipelines:', error);
      throw error;
    } finally {
      setIsLoadingPipelines(false);
    }
  };

  const toggleStageVisibility = (stageName: string) => {
    setSelectedStages(prev => ({
      ...prev,
      [stageName]: {
        ...prev[stageName],
        visible: !prev[stageName].visible
      }
    }));
  };

  const saveHubspotSettings = async () => {
    try {
      const { error } = await client
        .from('hubspot_access')
        .update({
          hubspot_pipeline: selectedPipelineId,
          hubspot_stages: selectedStages
        })
        .eq('user_id', user?.id);

      if (error) throw error;

      return Promise.resolve();
    } catch (error) {
      console.error('Error saving pipeline configuration:', error);
      throw error;
    }
  };

  // Check Hubspot connection
  useEffect(() => {
    const checkHubspotConnection = async () => {
      if (!user?.id) return;
      
      try {
        const { data, error } = await client
          .from('hubspot_access')
          .select('access_token')
          .eq('user_id', user.id)
          .single();

        if (error) {
          if (error.code !== 'PGRST116') {
            console.error('Error checking Hubspot connection:', error);
          }
          setIsHubspotConnected(false);
          return;
        }

        setIsHubspotConnected(!!data?.access_token);
      } catch (error) {
        console.error('Error checking Hubspot connection:', error);
        setIsHubspotConnected(false);
      }
    };

    checkHubspotConnection();
  }, [client, user?.id]);

  useEffect(() => {
    const checkHubspotSourceEnabled = async () => {
      if (!user?.id) return;
      
      try {
        const { data, error } = await client
          .from('user_settings')
          .select('source_hubspot')
          .eq('user_id', user.id)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error checking Hubspot source:', error);
        }

        setIsSourceEnabled(!!data?.source_hubspot);
      } catch (error) {
        console.error('Error checking Hubspot source:', error);
        setIsSourceEnabled(false);
      }
    };

    checkHubspotSourceEnabled();
  }, [user?.id, client]);

  const value = {
    isHubspotConnected,
    setIsHubspotConnected,
    handleConnectHubspot,
    handleDisconnectHubspot,
    hubspotPipelines,
    selectedPipelineId,
    selectedStages,
    isLoadingPipelines,
    setSelectedPipelineId,
    setSelectedStages,
    fetchHubspotPipelines,
    toggleStageVisibility,
    saveHubspotSettings,
    isSourceEnabled,
    setIsSourceEnabled,
  };

  return (
    <HubspotContext.Provider value={value}>
      {children}
    </HubspotContext.Provider>
  );
};

export const useHubspot = () => {
  const context = useContext(HubspotContext);
  if (context === undefined) {
    throw new Error('useHubspot must be used within a HubspotProvider');
  }
  return context;
};
