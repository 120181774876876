import React from 'react';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/alert';
import { Button } from '../../components/button';
import { Table, TableBody, TableCell, TableRow } from '../../components/table';

interface SlackChannel {
  id: string;
  name: string;
  analyze: boolean;
}

interface SlackAlertProps {
  isOpen: boolean;
  onClose: () => void;
  slackChannels: SlackChannel[];
  handleChannelToggle: (channelId: string) => void;
}

export const SlackAlert: React.FC<SlackAlertProps> = ({
  isOpen,
  onClose,
  slackChannels,
  handleChannelToggle,
}) => {
  return (
    <Alert open={isOpen} onClose={onClose}>
      <AlertTitle>Manage Slack</AlertTitle>
      <AlertDescription>
        <p className="mb-4">Select which channels to include in your daily briefs.</p>
        <div className="max-h-96 overflow-y-auto">
          <Table className="bg-white dark:bg-gray-900">
            <TableBody>
              {slackChannels.map((channel) => (
                <TableRow key={channel.id}>
                  <TableCell>
                    <div className="flex items-center">
                      <span className="text-gray-700">#{channel.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={channel.analyze}
                      onChange={() => handleChannelToggle(channel.id)}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Close
        </Button>
      </AlertActions>
    </Alert>
  );
}; 