import React from 'react';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/alert';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Switch } from '../../components/switch';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

interface HubspotStage {
  id: string;
  label: string;
}

interface HubspotPipeline {
  id: string;
  label: string;
  stages: HubspotStage[];
}

interface HubspotAlertProps {
  isOpen: boolean;
  onClose: () => void;
  mode: 'connect' | 'settings';
  // Connection props
  hubspotStep?: 'token' | 'pipeline';
  setHubspotStep?: (step: 'token' | 'pipeline') => void;
  isHubspotConnected?: boolean;
  hubspotApiKey?: string;
  setHubspotApiKey?: (key: string) => void;
  isHubspotInputFocused?: boolean;
  setIsHubspotInputFocused?: (focused: boolean) => void;
  handleConfirmHubspotConnect?: () => void;
  // Shared props
  isLoadingPipelines: boolean;
  hubspotPipelines: HubspotPipeline[];
  selectedPipelineId: string;
  setSelectedPipelineId: (id: string) => void;
  selectedStages: Record<string, { visible: boolean }>;
  toggleStageVisibility: (stageLabel: string) => void;
  // Settings props
  handleSaveHubspotSettings?: () => Promise<void>;
}

export const HubspotAlert: React.FC<HubspotAlertProps> = ({
  isOpen,
  onClose,
  mode,
  // Connection props
  hubspotStep = 'token',
  setHubspotStep = () => {},
  isHubspotConnected = false,
  hubspotApiKey = '',
  setHubspotApiKey = () => {},
  isHubspotInputFocused = false,
  setIsHubspotInputFocused = () => {},
  handleConfirmHubspotConnect = () => {},
  // Shared props
  isLoadingPipelines,
  hubspotPipelines,
  selectedPipelineId,
  setSelectedPipelineId,
  selectedStages,
  toggleStageVisibility,
  // Settings props
  handleSaveHubspotSettings = () => Promise.resolve(),
}) => {
  const handleClose = () => {
    onClose();
    if (mode === 'connect') {
      setHubspotStep('token');
    }
  };

  const renderPipelineConfig = () => (
    <div className="space-y-4">
      <p className="mb-4">Select which deal stages to include in your daily briefs.</p>
      {isLoadingPipelines ? (
        <div className="flex justify-center">
          <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500" />
        </div>
      ) : (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Pipeline
            </label>
            <select
              value={selectedPipelineId}
              onChange={(e) => setSelectedPipelineId(e.target.value)}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              {hubspotPipelines.map(pipeline => (
                <option key={pipeline.id} value={pipeline.id}>
                  {pipeline.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Deal Stages
            </label>
            <div className="space-y-2 max-h-60 overflow-y-auto border border-gray-200 rounded-md p-2">
              {hubspotPipelines
                .find(p => p.id === selectedPipelineId)
                ?.stages.map(stage => (
                  <div key={stage.id} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                    <span>{stage.label}</span>
                    <Switch
                      checked={selectedStages[stage.label]?.visible ?? true}
                      onChange={() => toggleStageVisibility(stage.label)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const renderContent = () => {
    if (mode === 'connect' && hubspotStep === 'token') {
      return (
        <div>
          <p className="mb-4">Enter your Hubspot API key to {isHubspotConnected ? 'update your connection' : 'connect your account'}.</p>
          <Input
            type="password"
            value={hubspotApiKey}
            onChange={(e) => setHubspotApiKey(e.target.value)}
            onFocus={() => setIsHubspotInputFocused(true)}
            onBlur={() => setIsHubspotInputFocused(false)}
            placeholder={isHubspotConnected ? "********************************************" : "Enter Hubspot API Key"}
            className="w-full"
          />
          <a 
            href="https://docs.google.com/document/d/1RzYVHwUrVmmjdrNVC8PWOHtbqZ8exLLF45np79fblds/edit?tab=t.0"
            target="_blank"
            rel="noopener noreferrer" 
            className="text-blue-600 hover:text-blue-800 text-sm mt-2 block"
          >
            Click here for step-by-step instructions.
          </a>
        </div>
      );
    }
    return renderPipelineConfig();
  };

  return (
    <Alert open={isOpen} onClose={handleClose}>
      <AlertTitle>
        {mode === 'connect' 
          ? (hubspotStep === 'token' ? 'Connect Hubspot' : 'Configure Hubspot')
          : 'Manage Hubspot'
        }
      </AlertTitle>
      <AlertDescription>
        {renderContent()}
      </AlertDescription>
      <AlertActions className="mt-4">
        <Button plain onClick={handleClose}>
          Cancel
        </Button>
        <Button 
          onClick={mode === 'connect' ? handleConfirmHubspotConnect : handleSaveHubspotSettings}
          disabled={mode === 'connect' 
            ? (hubspotStep === 'token' ? !hubspotApiKey : !selectedPipelineId)
            : !selectedPipelineId
          }
        >
          {mode === 'connect'
            ? (hubspotStep === 'token' 
                ? (isHubspotConnected ? 'Update Access Token' : 'Add Access Token')
                : 'Save Settings')
            : 'Save Settings'
          }
        </Button>
      </AlertActions>
    </Alert>
  );
}; 