import React from 'react';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/alert';
import { Input } from '../../components/input';
import { Button } from '../../components/button';

interface ShopifyAlertProps {
  isOpen: boolean;
  onClose: () => void;
  isShopifyConnected: boolean;
  shopifyApiKey: string;
  setShopifyApiKey: (key: string) => void;
  shopifySubdomain: string;
  setShopifySubdomain: (subdomain: string) => void;
  isShopifyInputFocused: boolean;
  setIsShopifyInputFocused: (focused: boolean) => void;
  isShopifySubdomainFocused: boolean;
  setIsShopifySubdomainFocused: (focused: boolean) => void;
  handleConfirmShopifyConnect: (apiKey: string, subdomain: string) => Promise<void>;
}

export const ShopifyAlert: React.FC<ShopifyAlertProps> = ({
  isOpen,
  onClose,
  isShopifyConnected,
  shopifyApiKey,
  setShopifyApiKey,
  shopifySubdomain,
  setShopifySubdomain,
  isShopifyInputFocused,
  setIsShopifyInputFocused,
  isShopifySubdomainFocused,
  setIsShopifySubdomainFocused,
  handleConfirmShopifyConnect,
}) => {
  return (
    <Alert open={isOpen} onClose={onClose}>
      <AlertTitle>{isShopifyConnected ? 'Update Shopify Access Token' : 'Connect Shopify'}</AlertTitle>
      <AlertDescription>
        <p className="mb-4">Enter your Shopify private app API key and subdomain to {isShopifyConnected ? 'update your store connection' : 'connect your store'}.</p>
        <Input
          type="password"
          value={shopifyApiKey}
          onChange={(e) => setShopifyApiKey(e.target.value)}
          onFocus={() => setIsShopifyInputFocused(true)}
          onBlur={() => setIsShopifyInputFocused(false)}
          placeholder={isShopifyConnected ? "****************************" : "Enter Shopify API Key"}
          style={{
            width: '100%',
            marginBottom: '0.5rem',
            backgroundColor: isShopifyConnected && !isShopifyInputFocused ? '#f3f4f6' : 'white',
            color: isShopifyConnected && !isShopifyInputFocused ? '#6b7280' : 'black',
          }}
        />
        <div className="flex items-center mb-2">
          <Input
            type="text"
            value={shopifySubdomain}
            onChange={(e) => setShopifySubdomain(e.target.value)}
            onFocus={() => setIsShopifySubdomainFocused(true)}
            onBlur={() => setIsShopifySubdomainFocused(false)}
            placeholder={isShopifyConnected ? "Subdomain: ****************************" : "Enter Subdomain"}
            style={{
              flexGrow: 1,
              backgroundColor: isShopifyConnected && !isShopifySubdomainFocused ? '#f3f4f6' : 'white',
              color: isShopifyConnected && !isShopifySubdomainFocused ? '#6b7280' : 'black',
            }}
          />
          <span className="ml-2 text-gray-600">.myshopify.com</span>
        </div>
        <p className="text-sm mt-2">
          <a 
            href="https://docs.google.com/document/d/1Ccb0WgaotpMZnkSNyFuS6BIT1swceleeeOEi6-VqZxI/edit?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:text-blue-800"
          >
            Click here for step-by-step instructions.
          </a>
          {" "}
          <span className="text-gray-600">
            Make sure to enable the following API scopes for your private app:
          </span>
        </p>
        <ul className="list-disc list-inside text-sm text-gray-600 mt-1">
          <li>read_products</li>
          <li>read_inventory</li>
          <li>read_orders</li>
          <li>read_customers</li>
        </ul>
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={async () => {
          try {
            await handleConfirmShopifyConnect(shopifyApiKey, shopifySubdomain);
            onClose();
          } catch (error) {
            alert('Failed to connect Shopify. Please try again.');
          }
        }}>
          {isShopifyConnected ? 'Update Access Token' : 'Add Access Token'}
        </Button>
      </AlertActions>
    </Alert>
  );
}; 