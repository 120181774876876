import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabaseClient } from './supabaseContext';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../config';

interface XeroContextType {
  isXeroConnected: boolean;
  xeroTenantId: string | null;
  setXeroTenantId: (value: string | null) => void;
  handleConnectXero: () => Promise<void>;
  handleDisconnectXero: () => Promise<void>;
}

const XeroContext = createContext<XeroContextType | undefined>(undefined);

export const XeroProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const [isXeroConnected, setIsXeroConnected] = useState(false);
  const [xeroTenantId, setXeroTenantId] = useState<string | null>(null);

  const handleConnectXero = async () => {
    try {
      const supabaseAccessToken = await getToken({ template: 'supabase' });

      const response = await fetch(`${API_URL}/api/xero/start-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabaseAccessToken}`,
          'ngrok-skip-browser-warning': 'true'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to start Xero authentication');
      }

      const { authUrl } = await response.json();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error connecting to Xero:', error);
      throw error;
    }
  };

  const handleDisconnectXero = async () => {
    try {
      const token = await getToken({ template: "supabase" });
      if (!token) {
        throw new Error('No authorization token found');
      }

      const response = await fetch(`${API_URL}/xero-disconnect`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to disconnect Xero');
      }

      setIsXeroConnected(false);
      setXeroTenantId(null);
    } catch (error) {
      console.error('Error disconnecting Xero:', error);
      throw error;
    }
  };

  // Check Xero connection
  useEffect(() => {
    const checkXeroConnection = async () => {
      try {
        const { data, error } = await client
          .from('xero_tokens')
          .select('*')
          .single();

        if (error) throw error;
        
        const isConnected = !!data;
        setIsXeroConnected(isConnected);
        setXeroTenantId(data?.tenant_id || null);
      } catch (error) {
        console.error('Error checking Xero connection:', error);
        setIsXeroConnected(false);
        setXeroTenantId(null);
      }
    };

    checkXeroConnection();
  }, [client]);

  const value = {
    isXeroConnected,
    xeroTenantId,
    setXeroTenantId,
    handleConnectXero,
    handleDisconnectXero,
  };

  return (
    <XeroContext.Provider value={value}>
      {children}
    </XeroContext.Provider>
  );
};

export const useXero = () => {
  const context = useContext(XeroContext);
  if (context === undefined) {
    throw new Error('useXero must be used within a XeroProvider');
  }
  return context;
};
