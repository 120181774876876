import React, { createContext, useContext, useMemo } from 'react';
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { useAuth } from '@clerk/clerk-react';

// Create context for the Supabase client
const SupabaseContext = createContext<SupabaseClient | undefined>(undefined);

// Provider component
export const SupabaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getToken } = useAuth();
  
  const client = useMemo(() => createClient(
    process.env.REACT_APP_SUPABASE_URL!,
    process.env.REACT_APP_SUPABASE_KEY!,
    {
      global: {
        fetch: async (url, options = {}) => {
          const token = await getToken({ template: "supabase" });
          
          if (!token) {
            throw new Error("No active session or failed to get Clerk token");
          }

          const headers = new Headers(options?.headers);
          headers.set("Authorization", `Bearer ${token}`);

          return fetch(url, {
            ...options,
            headers,
          });
        },
      },
    }
  ), [getToken]);

  return (
    <SupabaseContext.Provider value={client}>
      {children}
    </SupabaseContext.Provider>
  );
};

// Custom hook to use the Supabase client
export const useSupabaseClient = () => {
  const context = useContext(SupabaseContext);
  if (context === undefined) {
    throw new Error('useSupabaseClient must be used within a SupabaseProvider');
  }
  return context;
};
