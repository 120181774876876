import React from 'react';
import { SignUp } from "@clerk/clerk-react";

const SignUpPage: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8"> 
        <SignUp path="/signup" signInUrl="/login" />
      </div>
    </div>
  );
};

export default SignUpPage;