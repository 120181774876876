import React, { useState, useEffect } from 'react';
import cityList from '../components/city.list.json'; // Adjust the path as necessary

interface City {
  id: number;
  name: string;
  country: string;
}

interface AutoCompleteInputProps {
  onLocationSelect: (location: string | null) => void;
  initialLocation: string;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({ onLocationSelect, initialLocation }) => {
  const [query, setQuery] = useState(initialLocation);
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);

  useEffect(() => {
    setQuery(initialLocation);
  }, [initialLocation]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      const filteredCities = (cityList as City[]).filter((city: City) =>
        city.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredCities.slice(0, 10)); // Limit to 10 suggestions
      setActiveSuggestionIndex(-1); // Reset active suggestion index
    } else {
      setSuggestions([]);
      if (value === '') {
        onLocationSelect(null); // Trigger null location when input is cleared
      }
    }
  };

  const handleSuggestionClick = (city: City) => {
    const location = `${city.name}, ${city.country}`; // Display with space
    setQuery(location);
    setSuggestions([]);
    onLocationSelect(location.replace(', ', ',')); // Pass the location without space to the parent component
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent default behavior
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault(); // Prevent default behavior
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
      e.preventDefault(); // Prevent default behavior
      handleSuggestionClick(suggestions[activeSuggestionIndex]);
    }
  };

  return (
    <div className="autocomplete">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Choose your city"
        className="w-full border border-gray-300 rounded-md p-2.5 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-500"
      />
      {suggestions.length > 0 && (
        <ul className="border border-gray-300 rounded-md mt-1 bg-white">
          {suggestions.map((city, index) => (
            <li
              key={city.id}
              onClick={() => handleSuggestionClick(city)}
              className={`p-2 cursor-pointer hover:bg-gray-100 text-sm ${
                index === activeSuggestionIndex ? 'bg-gray-200' : ''
              }`}
            >
              {city.name}, {city.country}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteInput;
