import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabaseClient } from './supabaseContext';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../config';

interface SlackContextType {
  isSlackConnected: boolean;
  slackChannels: Array<{id: string, name: string, analyze: boolean}>;
  isSlackSettingsOpen: boolean;
  setIsSlackSettingsOpen: (isOpen: boolean) => void;
  setSlackChannels: (channels: Array<{id: string, name: string, analyze: boolean}>) => void;
  handleConnectSlack: () => void;
  handleDisconnectSlack: () => Promise<void>;
  handleChannelToggle: (channelId: string) => Promise<void>;
}

const SlackContext = createContext<SlackContextType | undefined>(undefined);

export const SlackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const [isSlackConnected, setIsSlackConnected] = useState(false);
  const [slackChannels, setSlackChannels] = useState<Array<{id: string, name: string, analyze: boolean}>>([]);
  const [isSlackSettingsOpen, setIsSlackSettingsOpen] = useState(false);

  const handleConnectSlack = () => {
    const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
    const redirectUri = `${process.env.REACT_APP_BACKEND_URL}/slack/oauth`;
    const userId = user?.id;
    
    const userScopes = [
      'channels:history',
      'channels:read',
      'groups:history', 
      'groups:read',
      'im:history',
      'im:read',
      'mpim:history',
      'mpim:read'
    ].join(',');
    
    const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&user_scope=${userScopes}&redirect_uri=${redirectUri}&state=${userId}`;
    
    window.location.href = slackUrl;
  };

  const handleDisconnectSlack = async () => {
    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/slack-disconnect`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      if (!response.ok) throw new Error('Failed to disconnect Slack');

      setIsSlackConnected(false);
    } catch (error) {
      console.error('Error disconnecting Slack:', error);
      throw error;
    }
  };

  const handleChannelToggle = async (channelId: string) => {
    try {
      const updatedChannels = slackChannels.map(channel => {
        if (channel.id === channelId) {
          return { ...channel, analyze: !channel.analyze };
        }
        return channel;
      });

      setSlackChannels(updatedChannels);

      const channelsToSave = updatedChannels.map(channel => JSON.stringify(channel));

      const { error } = await client
        .from('slack_credentials')
        .update({ channels: channelsToSave })
        .eq('user_id', user?.id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating channel settings:', error);
      setSlackChannels(slackChannels); // Revert on error
      throw error;
    }
  };

  // Check Slack connection on mount
  useEffect(() => {
    const checkSlackConnection = async () => {
      if (!user?.id) return;

      try {
        const { data, error } = await client
          .from('slack_credentials')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        setIsSlackConnected(!!data);
      } catch (error) {
        console.error('Error checking Slack connection:', error);
        setIsSlackConnected(false);
      }
    };

    checkSlackConnection();
  }, [user?.id, client]);

  // Fetch Slack channels when connected
  useEffect(() => {
    const fetchSlackChannels = async () => {
      if (!user?.id || !isSlackConnected) return;

      try {
        const { data, error } = await client
          .from('slack_credentials')
          .select('channels')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        
        if (data?.channels) {
          const parsedChannels = data.channels.map((channel: string) => {
            try {
              return JSON.parse(channel);
            } catch (e) {
              return channel;
            }
          });
          setSlackChannels(parsedChannels);
        }
      } catch (error) {
        console.error('Error fetching Slack channels:', error);
      }
    };

    fetchSlackChannels();
  }, [user?.id, isSlackConnected, client]);

  const value = {
    isSlackConnected,
    slackChannels,
    isSlackSettingsOpen,
    setIsSlackSettingsOpen,
    setSlackChannels,
    handleConnectSlack,
    handleDisconnectSlack,
    handleChannelToggle,
  };

  return (
    <SlackContext.Provider value={value}>
      {children}
    </SlackContext.Provider>
  );
};

export const useSlack = () => {
  const context = useContext(SlackContext);
  if (context === undefined) {
    throw new Error('useSlack must be used within a SlackProvider');
  }
  return context;
};
