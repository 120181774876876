import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabaseClient } from './supabaseContext';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../config';

interface ShopifyContextType {
  isShopifyConnected: boolean;
  shopifySubdomain: string;
  setShopifySubdomain: (value: string) => void;
  setIsShopifyConnected: (value: boolean) => void;
  handleConnectShopify: (apiKey: string, subdomain: string) => Promise<void>;
  handleDisconnectShopify: () => Promise<void>;
  handleConfirmShopifyConnect: (apiKey: string, subdomain: string) => Promise<void>;
}

const ShopifyContext = createContext<ShopifyContextType | undefined>(undefined);

export const ShopifyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [shopifySubdomain, setShopifySubdomain] = useState('');

  const handleConnectShopify = async (apiKey: string, subdomain: string) => {
    if (!user?.id) {
      throw new Error('User ID not available');
    }

    try {
      if (isShopifyConnected) {
        const { error } = await client
          .from('shopify_access')
          .update({ 
            access_token: apiKey, 
            shop_subdomain: subdomain 
          })
          .eq('user_id', user.id);

        if (error) throw error;
      } else {
        const { error } = await client
          .from('shopify_access')
          .insert({ 
            user_id: user.id, 
            access_token: apiKey, 
            shop_subdomain: subdomain 
          });

        if (error) throw error;
      }

      setIsShopifyConnected(true);
      setShopifySubdomain(subdomain);
    } catch (error) {
      console.error('Error connecting Shopify:', error);
      throw error;
    }
  };

  const handleDisconnectShopify = async () => {
    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/shopify-disconnect`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to disconnect Shopify');
      }

      setIsShopifyConnected(false);
      setShopifySubdomain('');
    } catch (error) {
      console.error('Error disconnecting Shopify:', error);
      throw error;
    }
  };

  const handleConfirmShopifyConnect = async (apiKey: string, subdomain: string) => {
    try {
      await handleConnectShopify(apiKey, subdomain);
      return Promise.resolve();
    } catch (error) {
      console.error('Error connecting Shopify:', error);
      throw error;
    }
  };

  // Check Shopify connection
  useEffect(() => {
    const checkShopifyConnection = async () => {
      try {
        const { data, error } = await client
          .from('shopify_access')
          .select('shop_subdomain')
          .limit(1);

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        if (data && data.length > 0) {
          setIsShopifyConnected(true);
          setShopifySubdomain(data[0].shop_subdomain);
        } else {
          setIsShopifyConnected(false);
        }
      } catch (error) {
        console.error('Error checking Shopify connection:', error);
        setIsShopifyConnected(false);
      }
    };

    checkShopifyConnection();
  }, [client]);

  const value = {
    isShopifyConnected,
    shopifySubdomain,
    setShopifySubdomain,
    setIsShopifyConnected,
    handleConnectShopify,
    handleDisconnectShopify,
    handleConfirmShopifyConnect,
  };

  return (
    <ShopifyContext.Provider value={value}>
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => {
  const context = useContext(ShopifyContext);
  if (context === undefined) {
    throw new Error('useShopify must be used within a ShopifyProvider');
  }
  return context;
};
