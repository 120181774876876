import React, { useEffect, useState } from 'react';
import { useSupabaseClient } from '../context/supabaseContext';
import { useUser, useOrganization, OrganizationSwitcher, UserButton, useClerk, SignOutButton } from '@clerk/clerk-react';
import { Heading } from '../components/heading';
import { Select } from '../components/select';
import { Button } from '../components/button';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

interface Email {
  id: string;
  created_at: string;
  html: string;
}

const CustomOrganizationSwitcher = (props: React.ComponentProps<typeof OrganizationSwitcher>) => (
    <OrganizationSwitcher 
      {...props}
      appearance={{
        elements: {
          organizationSwitcherTrigger: {
            '&[data-clerk-organization-switcher-trigger]': {
              '&[data-clerk-organization-switcher-trigger-empty]': {
                '&::before': {
                  content: '"Please select an organization"',
                },
              },
            },
          },
          // This targets the "Create Organization" option and hides it
          organizationSwitcherPopoverActionButton__createOrganization: {
            display: 'none',
          },
        },
      }}
    />
  );

const EmailViewer: React.FC = () => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const client = useSupabaseClient();
  const { user } = useUser();
  const { organization, isLoaded } = useOrganization();
  const { openUserProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);
  const location = useLocation();

  const hasOrganization = !!organization?.id;

  useEffect(() => {
    const fetchEmails = async () => {
      if (!user) {
        console.log('No user available');
        return;
      }

      try {
        const { data, error } = await client
          .from('sent_emails')
          .select('id, created_at, html')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (error) {
          throw error;
        }

        console.log('Fetched emails:', data);
        setEmails(data || []);
        if (data && data.length > 0) {
          setEmails(data);
          setSelectedEmail(data[0]);
        } else {
          setEmails([]);
          setSelectedEmail(null);
        }
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
  }, [user, client]);

  useEffect(() => {
    console.log('EmailViewer component mounted');
    console.log('User:', user);
    console.log('Supabase client:', client);
  }, [user, client]);

  const handleEmailSelect = (emailId: string) => {
    const email = emails.find(e => e.id === emailId);
    if (email) {
      setSelectedEmail(email);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="w-full max-w-4xl mx-auto py-10 px-4 pb-32 bg-gray-100">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <CustomOrganizationSwitcher 
            hidePersonal
            appearance={{
              elements: {
                rootBox: {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                },
                organizationSwitcherTrigger: {
                  padding: '6px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#f3f4f6',
                  '&:hover': {
                    backgroundColor: '#e5e7eb',
                  },
                },
              },
            }}
          />
        </div>
        <nav>
          <ul className="flex space-x-4">
            <li>
              <Link 
                to="/briefs" 
                className={clsx(
                  "text-sm",
                  location.pathname === '/briefs' 
                    ? "text-gray-900 font-medium" 
                    : "text-gray-500 hover:text-gray-700",
                  "no-underline hover:underline"
                )}
              >
                Daily Briefs
              </Link>
            </li>
            <li>
              <Link 
                to="/settings" 
                className={clsx(
                  "text-sm",
                  location.pathname === '/settings' 
                    ? "text-gray-900 font-medium" 
                    : "text-gray-500 hover:text-gray-700",
                  "no-underline hover:underline"
                )}
              >
                Settings
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="space-y-6 mt-6">
        <section className="bg-white rounded-lg shadow p-6">
          <Heading level={2} className="text-xl font-semibold">Daily Briefs</Heading>
          <p className="text-sm text-gray-500 mb-4">View any of your past daily briefs</p>

          {emails.length > 0 ? (
            <>
              <div className="mb-4">
                <Select
                  id="emailSelect"
                  value={selectedEmail?.id || ''}
                  onChange={(e) => handleEmailSelect(e.target.value)}
                  className="w-full"
                >
                  {emails.map((email) => (
                    <option key={email.id} value={email.id}>
                      {formatDate(email.created_at)}
                    </option>
                  ))}
                </Select>
              </div>

              {selectedEmail && (
                <div className="mt-6">
                  <div 
                    className="border rounded-lg p-4 overflow-auto max-h-[600px]"
                    dangerouslySetInnerHTML={{ __html: selectedEmail.html }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500">No daily briefs are available yet, check back soon!</p>
            </div>
          )}
        </section>
      </div>

      {/* User Profile and Sign Out at the bottom */}
      <div className="mt-8 border-t pt-8 flex justify-between items-center">
        <div 
          className={clsx(
            "flex min-w-0 items-center gap-3 cursor-pointer rounded-lg p-2 transition-colors duration-200",
            isUserProfileHovered ? "bg-zinc-100 dark:bg-zinc-800" : ""
          )}
          onClick={() => openUserProfile()}
          onMouseEnter={() => setIsUserProfileHovered(true)}
          onMouseLeave={() => setIsUserProfileHovered(false)}
        >
          <UserButton afterSignOutUrl="/" />
          <span className="min-w-0 flex-1">
            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
              {user?.firstName} {user?.lastName}
            </span>
            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
              {user?.primaryEmailAddress?.emailAddress}
            </span>
          </span>
        </div>

        <SignOutButton>
          <Button color="red">Sign Out</Button>
        </SignOutButton>
      </div>
    </div>
  );
};

export default EmailViewer;
