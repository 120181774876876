import React from 'react';
import logo from '../assets/random-24.svg';

const Landing = () => {
  return (
    <div className="flex flex-col min-h-screen bg-[#0d0d0d] text-white">
      {/* Header and hero section */}
      <div className="flex-grow">
        {/* Header topbar */}
        <header className="relative w-full h-[63px]">
          <nav className="flex px-8 py-2 relative">
            <div className="w-[50px] h-[51px] justify-start">
              <img src={logo} alt="Logo" className="w-full h-full" style={{ filter: 'invert(1)' }} />
            </div>
            <div className="flex items-center justify-center flex-grow">
              <div className="backdrop-blur-[10px] rounded-[50px] px-8 py-2 bg-neutral-900/60 flex items-center justify-around border border-neutral-800">
                <a href="#" className="text-base text-[#7a7a7a] px-4 hover:text-white">Features</a>
                <a href="#" className="text-base text-[#7a7a7a] px-4 hover:text-white">How it works</a>
                <a href="#" className="text-base text-[#7a7a7a] px-4 hover:text-white">Testimonials</a>
                <a href="#" className="text-base text-[#7a7a7a] px-4 hover:text-white">FAQs</a>
              </div>
            </div>
            <button 
              className="justify-end backdrop-blur-[10px] rounded-[50px] w-[127px] h-[53px] bg-[#ddff00] border border-neutral-800 font-medium text-base  text-[#0d0d0d]"
              onClick={() => window.location.href = '/login'}
            >
              Login
            </button>
          </nav>
        </header>

        {/* Hero banner/section */}
        <section className="relative w-full h-[816px] flex flex-col items-center justify-center">
          <div className="text-center mb-8">
            <h1 className="text-[80px] font-medium leading-[92px] bg-clip-text text-transparent bg-gradient-to-bl from-[#fffff7] to-[#727267] mb-4">
              Talk to your Business
            </h1>
            <p className="text-lg text-[#7d7f78] max-w-[791px] mx-auto">
              Array is your SMB's Chief of Staff. It knows everything and
              everyone in your company, and can act on your behalf.
            </p>
          </div>
          <form className="relative w-[465px] h-[70px] mb-12">
            <input
              className="w-full h-full rounded-[50px] bg-[#0d0d0d] border border-gray-700 px-6 text-[#cccccc]"
              placeholder="name@email.com"
              type="email"
            />
            <button
              className="absolute right-[10px] top-[10px] shadow-[0px_20px_35px_0px_rgba(221,255,0,0.20)] rounded-[40px] w-[145px] h-[50px] bg-[#ddff00] text-[#0d0d0d] font-medium"
              type="submit"
            >
              Get notified
            </button>
          </form>
          {/* Add image placeholders here */}
        </section>
      </div>

      {/* Footer */}
      <footer className="relative w-full py-8 bg-transparent">
        <div className="max-w-[1280px] mx-auto flex justify-center items-center px-4">
          <div className="flex space-x-4 text-sm text-[#7a7a7a]">
            <a href="#" className="hover:text-white">Contact</a>
            <a href="#" className="hover:text-white">Privacy & Cookie Policy</a>
            <a href="#" className="hover:text-white">Terms & Conditions</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;