import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Heading } from '../components/heading';
import { Input } from '../components/input';
import { Button } from '../components/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { useSupabaseClient } from '../context/supabaseContext';
import { useAuth, useOrganization, useOrganizationList, OrganizationSwitcher, useUser, UserButton, useClerk, SignOutButton, CreateOrganization, SignUp } from '@clerk/clerk-react';
import { API_URL } from '../config';
import { Textarea } from '../components/textarea';
import clsx from 'clsx';
import { EnvelopeIcon, VideoCameraIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { Badge } from '../components/badge';
import { SparklesIcon, ArrowPathIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import AutoCompleteInput from '../components/AutoCompleteInput';
import ConfettiExplosion from 'react-confetti-explosion';
import { Link } from 'react-router-dom';
import { useSlack } from '../context/slackContext';
import { useXero } from '../context/xeroContext';
import { useHubspot } from '../context/hubspotContext';
import { useShopify } from '../context/shopifyContext';
import { useGoogle } from '../context/googleContext';
import { TestEmailSection } from '../components/TestEmailSection';
import { HubspotAlert } from './settings-modals/HubspotAlert';
import { GoogleAlert } from './settings-modals/GoogleAlert';
import { ShopifyAlert } from './settings-modals/ShopifyAlert';
import { SlackAlert } from './settings-modals/SlackAlert';

const CustomOrganizationSwitcher = (props: React.ComponentProps<typeof OrganizationSwitcher>) => (
  <OrganizationSwitcher 
    {...props}
    appearance={{
      elements: {
        organizationSwitcherTrigger: {
          '&[data-clerk-organization-switcher-trigger]': {
            '&[data-clerk-organization-switcher-trigger-empty]': {
              '&::before': {
                content: '"Please select an organization"',
              },
            },
          },
        },
        // This targets the "Create Organization" option and hides it
        organizationSwitcherPopoverActionButton__createOrganization: {
          display: 'none',
        },
      },
    }}
  />
);

const Tooltip: React.FC<{ text: string; children: React.ReactNode }> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-max max-w-xs p-2 mt-2 text-xs text-white bg-gray-900 rounded-md shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

const SettingsNew: React.FC = () => {
  const { organization, isLoaded } = useOrganization();
  const [members, setMembers] = useState<any[]>([]);
  const [businessData, setBusinessData] = useState({
    name: '',
    details: ''
  });
  const [initialBusinessData, setInitialBusinessData] = useState({
    name: '',
    details: ''
  });
  const [isBusinessDataModified, setIsBusinessDataModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { user } = useUser();
  const { openUserProfile, openOrganizationProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);

  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const location = useLocation();

  const [dailyReports, setDailyReports] = useState({
    email: true,
    video: false,
    audio: false,
  });

  const [jobData, setJobData] = useState({
    title: '',
    description: '',
    location: '',
  });
  const [initialJobData, setInitialJobData] = useState({
    title: '',
    description: '',
    location: '',
  });
  const [isJobDataModified, setIsJobDataModified] = useState(false);
  const [isJobSaving, setIsJobSaving] = useState(false);
  const [jobSaveSuccess, setJobSaveSuccess] = useState(false);

  const hasOrganization = !!organization?.id;

  const dataSourcesRef = useRef<HTMLElement>(null);

  const [firstEmailSent, setFirstEmailSent] = useState<boolean>(false);
  const [showSetupSuccess, setShowSetupSuccess] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<string | null>(null);

  // Add these new state variables at the beginning of your component
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [testEmailMessage, setTestEmailMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [customUserId, setCustomUserId] = useState('');
  const [customEmailRecipient, setCustomEmailRecipient] = useState('');
  const [isCustomTestEmailSending, setIsCustomTestEmailSending] = useState(false);
  const [customTestEmailMessage, setCustomTestEmailMessage] = useState<{ type: string; text: string } | null>(null);

  // Company auto-populate states
  const [isCompanyAutoPopulating, setIsCompanyAutoPopulating] = useState(false);
  const [companyAutoPopulateStatus, setCompanyAutoPopulateStatus] = useState<'idle' | 'success' | 'error'>('idle');

  // Job auto-populate states
  const [isJobAutoPopulating, setIsJobAutoPopulating] = useState(false);
  const [jobAutoPopulateStatus, setJobAutoPopulateStatus] = useState<'idle' | 'success' | 'error'>('idle');


  // Add this state near the top of your component
  const [bulkTestEmailRecipient, setBulkTestEmailRecipient] = useState('');
  const [isBulkTestingSending, setIsBulkTestingSending] = useState(false);
  const [bulkTestResult, setBulkTestResult] = useState<any>(null);

  // Add this near the top of the file with other imports
  const isDevelopment = window.location.hostname === 'localhost';

  // Add these new state variables near the top of the component
  
  const [isSticky, setIsSticky] = useState(false);
    // Add these near the top of the SettingsNew component
  const [isSetupInProgress, setIsSetupInProgress] = useState(false);

  // Add this with the other utility functions
  const setMessage = (message: string) => {
    // Implement message display logic if needed
    console.log(message);
  };
  
  const [isHubspotInputFocused, setIsHubspotInputFocused] = useState(false);
  const [hubspotApiKey, setHubspotApiKey] = useState('');
  const [hubspotStep, setHubspotStep] = useState<'token' | 'pipeline'>('token')
  const [isHubspotSettingsOpen, setIsHubspotSettingsOpen] = useState(false);
  const [isHubspotAlertOpen, setIsHubspotAlertOpen] = useState(false); 

  const [isShopifyAlertOpen, setIsShopifyAlertOpen] = useState(false);
  const [shopifyApiKey, setShopifyApiKey] = useState('')
  const [isShopifySubdomainFocused, setIsShopifySubdomainFocused] = useState(false);
  const [isShopifyInputFocused, setIsShopifyInputFocused] = useState(false);
  const [isExploding, setIsExploding] = useState(false);

  const { 
    isGoogleConnected,  // Add this to destructuring
    connectedEmail,
    handleConnectGoogle,
    handleDisconnectGoogle,
    googleAnalyticsProperties,
    googleAnalyticsPropertyId,
    currentGAProperty,
    isLoadingProperties,
    isGoogleSettingsAlertOpen,
    setIsGoogleSettingsAlertOpen,
    setGoogleAnalyticsPropertyId,
    handleOpenGoogleSettings,
    confirmGoogleSettings,
  } = useGoogle();

  const { 
    isSlackConnected,
    slackChannels,
    isSlackSettingsOpen,
    setIsSlackSettingsOpen,
    handleConnectSlack,
    handleDisconnectSlack,
    handleChannelToggle
  } = useSlack();

  const { 
    isXeroConnected,
    xeroTenantId,
    handleConnectXero,
    handleDisconnectXero,
  } = useXero();

  const { 
    isHubspotConnected,
    handleConnectHubspot,
    handleDisconnectHubspot,
    hubspotPipelines,
    selectedPipelineId,
    selectedStages,
    isLoadingPipelines,
    setSelectedPipelineId,
    setSelectedStages,
    fetchHubspotPipelines,
    toggleStageVisibility,
    saveHubspotSettings,
    isSourceEnabled,
    setIsSourceEnabled,
  } = useHubspot();

  const { 
    isShopifyConnected,
    shopifySubdomain,
    setShopifySubdomain,
    handleConnectShopify,
    handleDisconnectShopify,
    handleConfirmShopifyConnect,
  } = useShopify();

  // Consolidate organization and data fetching effects
  useEffect(() => {
    if (isLoaded && organization) {
      // Fetch all initial data when org loads
      const initializeData = async () => {
        await Promise.all([
          fetchMembers(),
          syncOrganizationToSupabase(organization),
          fetchBusinessData(),
          fetchJobData()
        ]);
      };
      
      initializeData();
    }
  }, [isLoaded, organization]);

  // Consolidate user-related effects
  useEffect(() => {
    if (user) {
      // Initialize user settings and check for errors
      const initializeUser = async () => {
        await fetchUserSettings();
        
        // Check URL params for errors
        const queryParams = new URLSearchParams(location.search);
        const error = queryParams.get('error');
        if (error) {
          setMessage(`Error: ${error}`);
        }
      };

      initializeUser();
    }
  }, [user, location.search]); // Include location.search as dependency

  // Consolidate connection status effects
  useEffect(() => {
    const checkFullyConnectedState = () => {
      if (isGoogleConnected && !firstEmailSent) {
        setShowSetupSuccess(true);
      } else {
        setShowSetupSuccess(false);
      }
    };

    checkFullyConnectedState();
  }, [isGoogleConnected, firstEmailSent]);

  // UI effects
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleShopifyConnect = () => {
    setIsShopifyAlertOpen(true);
    setShopifyApiKey(''); // Reset the API key when opening the alert
  };

  const fetchMembers = async () => {
    if (organization) {
      const memberList = await organization.getMemberships();
      setMembers(memberList.data);
    }
  };

  const syncOrganizationToSupabase = async (org: any) => {
    if (!org || !org.id || !org.name || !user?.id) return;

    try {
      const { data, error } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', org.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      const orgData = {
        organization_id: org.id,
        company_name: org.name,
        user_id: user.id,
        company_logo_url: org.imageUrl || null // Add this line to include the logo URL
      };

      if (data) {
        // Update existing record
        const { error: updateError } = await client
          .from('org_info')
          .update(orgData)
          .eq('organization_id', org.id);

        if (updateError) throw updateError;
      } else {
        // Insert new record
        const { error: insertError } = await client
          .from('org_info')
          .insert(orgData);

        if (insertError) throw insertError;
      }

      // Refresh business data after syncing
      await fetchBusinessData();
    } catch (error) {
      console.error('Error syncing organization:', error);
    }
  };

  const fetchBusinessData = async () => {
    try {
      if (!organization?.id) return;

      const { data, error } = await client
        .from('org_info')
        .select('company_name, company_details')
        .eq('organization_id', organization.id)
        .single();

      if (error) throw error;

      const fetchedData = {
        name: organization.name || data?.company_name || '',
        details: data?.company_details || ''
      };
      setBusinessData(fetchedData);
      setInitialBusinessData(fetchedData);
    } catch (error) {
      console.error('Error fetching business data:', error);
    }
  };

  const fetchJobData = async () => {
    try {
      const { data, error } = await client
        .from('user_settings')
        .select('job_title, job_description, location')
        .eq('user_id', user?.id)
        .single();

      if (error) {
        console.error('Error fetching job data:', error);
        return;
      }

      if (data) {
        const fetchedData = {
          title: data.job_title || '',
          description: data.job_description || '',
          location: data.location ? data.location.replace(',', ', ') : '' // Display with space
        };
        setJobData(fetchedData);
        setInitialJobData(fetchedData);
      }
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  const fetchUserSettings = async () => {
    if (!user) return;

    try {
      const { data, error } = await client
        .from('user_settings')
        .select('channel_email, channel_video, channel_audio, source_hubspot, first_email_sent, user_email')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setDailyReports({
          email: data.channel_email ?? true,
          video: data.channel_video ?? false,
          audio: data.channel_audio ?? false,
        });
        setIsSourceEnabled(data.source_hubspot ?? false);
        setFirstEmailSent(data.first_email_sent ?? false);
        setUserEmail(data.user_email ?? null);
      } else {
        // If no data is found, set email to true by default
        setDailyReports(prev => ({ ...prev, email: true }));
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      // In case of error, still set email to true by default
      setDailyReports(prev => ({ ...prev, email: true }));
    }
  };

  // Modify the useEffect hook to only show banner when needed
  useEffect(() => {
    const checkFullyConnectedState = () => {
      if (isGoogleConnected && !firstEmailSent) {
        setShowSetupSuccess(true);
      } else {
        setShowSetupSuccess(false);
      }
    };

    checkFullyConnectedState();
  }, [isGoogleConnected, firstEmailSent]);

  // Modify handleSendFirstEmail to set firstEmailSent only on success
  const handleSendFirstEmail = async () => {
    try {
      setIsExploding(true); // Start confetti
      
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/send-first-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true'  // Add this
        },
      });

      if (!response.ok) {
        throw new Error('Failed to send first email');
      }

      // Set firstEmailSent to true only after successful response
      setFirstEmailSent(true);
    } catch (error) {
      console.error('Error sending first email:', error);
    } finally {
      setIsExploding(false); // Stop confetti after the request is complete
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setBusinessData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsBusinessDataModified(JSON.stringify(newData) !== JSON.stringify(initialBusinessData));
      return newData;
    });
  };

  const handleJobInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setJobData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsJobDataModified(JSON.stringify(newData) !== JSON.stringify(initialJobData));
      return newData;
    });
  };

  const handleLocationSelect = (location: string | null) => {
    setJobData(prevData => {
      const newData = { ...prevData, location: location ? location.replace(',', ', ') : '' };
      setIsJobDataModified(JSON.stringify(newData) !== JSON.stringify(initialJobData));
      return newData;
    });
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      if (!organization?.id) {
        throw new Error('Organization ID is not available');
      }

      const token = await getToken({ template: "supabase" });
      // First, check if a record exists
      const { data: existingData, error: fetchError } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', organization.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let result;
      if (existingData) {
        // If record exists, update it
        result = await client
          .from('org_info')
          .update({
            company_name: businessData.name,
            company_details: businessData.details,
          })
          .eq('organization_id', organization.id);
      } else {
        // If record doesn't exist, insert a new one
        result = await client
          .from('org_info')
          .insert({
            organization_id: organization.id,
            company_name: businessData.name,
            company_details: businessData.details,
          });
      }

      if (result.error) throw result.error;

      setInitialBusinessData(businessData);
      setIsBusinessDataModified(false);
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000); // Reset success state after 3 seconds
    } catch (error) {
      console.error('Error saving business data:', error);
      alert('Failed to save business data. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveJobSettings = async () => {
    setIsJobSaving(true);
    try {
      if (!user?.id) {
        throw new Error('User ID is not available');
      }

      const { error } = await client
        .from('user_settings')
        .upsert({
          user_id: user.id,
          job_title: jobData.title,
          job_description: jobData.description,
          location: jobData.location ? jobData.location.replace(', ', ',') : null // Save null if empty
        }, { onConflict: 'user_id' });

      if (error) throw error;

      setInitialJobData(jobData);
      setIsJobDataModified(false);
      setJobSaveSuccess(true);
      setTimeout(() => setJobSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Error saving job data:', error);
      alert('Failed to save job data. Please try again.');
    } finally {
      setIsJobSaving(false);
    }
  };

  const handleDailyReportToggle = async (method: 'email' | 'video' | 'audio') => {
    if (!isGoogleConnected || !user || !user.id) return;

    const newValue = !dailyReports[method];
    setDailyReports(prev => ({ ...prev, [method]: newValue }));

    try {
      const { error } = await client
        .from('user_settings')
        .upsert(
          {
            user_id: user.id,
            [`channel_${method}`]: newValue,
          },
          { 
            onConflict: 'user_id'
          }
        )
        .select();

      if (error) throw error;
    } catch (error) {
      console.error(`Error updating ${method} setting:`, error);
      // Revert the state if the update failed
      setDailyReports(prev => ({ ...prev, [method]: !newValue }));
      alert(`Failed to update ${method} setting. Please try again.`);
    }
  };
  
  // Hubspot
  const handleHubspotConnect = () => {
    setIsHubspotAlertOpen(true);
    setHubspotApiKey(''); // Reset the API key when opening the alert
  };

  const handleConfirmHubspotConnect = async () => {
    try {
      // First connect Hubspot
      await handleConnectHubspot(hubspotApiKey);
      
      // After successful connection, fetch pipelines
      await fetchHubspotPipelines();
      
      // Move to pipeline configuration step
      setHubspotStep('pipeline');
      
    } catch (error) {
      console.error('Error connecting Hubspot:', error);
      alert('Failed to connect Hubspot. Please check your API key and try again.');
      // Keep the alert open but reset the API key
      setHubspotApiKey('');
    }
  };

  const handleHubspotSettings = async () => {
    setIsHubspotSettingsOpen(true);
    try {
      await fetchHubspotPipelines();
    } catch (error) {
      console.error('Error loading Hubspot settings:', error);
      alert('Failed to load Hubspot settings. Please try again.');
      setIsHubspotSettingsOpen(false);
    }
  };

  const handleSaveHubspotSettings = async () => {
    try {
      await saveHubspotSettings();
      setIsHubspotSettingsOpen(false);
      setIsHubspotAlertOpen(false);
    } catch (error) {
      alert('Failed to save pipeline configuration. Please try again.');
    }
  };

  // Auto-populate job and org info
  const handleAutoPopulateCompanyInfo = async () => {
    if (!user?.id) return;

    setIsCompanyAutoPopulating(true);
    setCompanyAutoPopulateStatus('idle');

    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/auto-populate-company-info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'  // Add this
        },
        body: JSON.stringify({ user_id: user.id })
      });

      console.log('Response from auto-populate-company-info:', response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Parsed data:', data);

      if (data.error) {
        throw new Error(data.error);
      }

      if (data.summary) {
        setBusinessData(prevData => ({
          ...prevData,
          details: data.summary
        }));
        setCompanyAutoPopulateStatus('success');
        setIsBusinessDataModified(true);
      } else {
        throw new Error('No summary received from the server');
      }
    } catch (error) {
      console.error('Error auto-populating company info:', error);
      setCompanyAutoPopulateStatus('error');
    } finally {
      setIsCompanyAutoPopulating(false);
      setTimeout(() => setCompanyAutoPopulateStatus('idle'), 2000);
    }
  };

  const handleAutoPopulateJobInfo = async () => {
    if (!user?.id) return;

    setIsJobAutoPopulating(true);
    setJobAutoPopulateStatus('idle');

    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/auto-populate-job-info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'  // Add this
        },
        body: JSON.stringify({ user_id: user.id })
      });

      console.log('Response from auto-populate-job-info:', response);
      if (!response.ok) {
        throw new Error('Failed to auto-populate job info');
      }

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      if (data.job_info && data.job_info.job_title && data.job_info.job_description) {
        setJobData(prevData => ({
          ...prevData,
          title: data.job_info.job_title,
          description: data.job_info.job_description
        }));
        setJobAutoPopulateStatus('success');
        setIsJobDataModified(true);
      } else {
        throw new Error('Invalid or empty job info received');
      }
    } catch (error) {
      console.error('Error auto-populating job info:', error);
      setJobAutoPopulateStatus('error');
    } finally {
      setIsJobAutoPopulating(false);
      setTimeout(() => setJobAutoPopulateStatus('idle'), 2000);
    }
  };

  const handleInvite = () => {
    if (organization) {
      openOrganizationProfile();
    }
  };

  const handleConnectClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (dataSourcesRef.current) {
      dataSourcesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto py-8 px-4 relative pb-20 bg-gray-100">
       <div className="flex justify-between items-center mb-4">
     {hasOrganization ? (
       <div className="flex items-center">
         <CustomOrganizationSwitcher 
           hidePersonal
           appearance={{
             elements: {
               rootBox: {
                 display: 'flex',
                 justifyContent: 'flex-start',
                 alignItems: 'center',
               },
               organizationSwitcherTrigger: {
                 padding: '6px 12px',
                 borderRadius: '6px',
                 backgroundColor: '#f3f4f6',
                 '&:hover': {
                   backgroundColor: '#e5e7eb',
                 },
               },
             },
           }}
         />
       </div>
     ) : (
       <Heading level={2} className="text-2xl font-semibold">Welcome</Heading>
     )}

     <nav>
       <ul className="flex space-x-4">
         <li>
           <Link 
             to="/briefs" 
             className={clsx(
               "text-sm",
               location.pathname === '/briefs' 
                 ? "text-gray-900 font-medium" 
                 : "text-gray-500 hover:text-gray-700",
               "no-underline hover:underline"
             )}
           >
             Daily Briefs
           </Link>
         </li>
         <li>
           <Link 
             to="/settings" 
             className={clsx(
               "text-sm",
               location.pathname === '/settings' 
                 ? "text-gray-900 font-medium" 
                 : "text-gray-500 hover:text-gray-700",
               "no-underline hover:underline"
             )}
           >
             Settings
           </Link>
         </li>
       </ul>
     </nav>
   </div>

      {!isGoogleConnected && (
        <div className="sticky top-4 z-10 transition-all duration-200 [filter:drop-shadow(0_10px_8px_rgb(0_0_0_/0.04))_drop-shadow(0_4px_3px_rgb(0_0_0_/0.1))]">
          <div className={`mx-auto transition-all duration-200 ${
            isSticky 
              ? 'max-w-3xl' 
              : 'max-w-4xl'
          }`}>
            <div className={`flex bg-blue-100 rounded-lg transition-all duration-200 ${
              isSticky 
                ? 'p-4 mb-4 mt-4' 
                : 'p-6 mb-6 mt-8'
            }`}>
              <svg
                className="w-6 h-6 text-blue-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <div className="ml-4 text-base text-black">
                <span className="font-semibold">Welcome!</span>{' '}
                To get started, please fill in your details and {' '}
                <a
                  href="#data-sources"
                  onClick={handleConnectClick}
                  className="underline"
                >
                  connect some data sources
                </a>{' '}
                to start receiving your daily briefs
              </div>
            </div>
          </div>
        </div>
      )}

{showSetupSuccess && (
  <div className="sticky top-4 z-10 transition-all duration-200 [filter:drop-shadow(0_10px_8px_rgb(0_0_0_/0.04))_drop-shadow(0_4px_3px_rgb(0_0_0_/0.1))]">
    <div className={`mx-auto transition-all duration-200 ${
      isSticky 
        ? 'max-w-3xl' // narrower when sticky
        : 'max-w-4xl' // wider when not sticky
    }`}>
      <div className={`flex items-center bg-green-100 rounded-lg transition-all duration-200 ${
        isSticky 
          ? 'p-4 mb-4 mt-4' 
          : 'p-6 mb-6 mt-8'
      }`}>
        <svg
          className="w-6 h-6 text-green-600 flex-shrink-0"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
        </svg>
        <div className="ml-4 text-base text-black flex-grow">
          <span className="font-semibold">Great!</span>{' '}
          {firstEmailSent 
            ? `Your first daily brief has been sent to ${userEmail}. You'll receive them every day at 7:00AM ET`
            : "Continue connecting data sources or get your first brief now"}
        </div>
        {!firstEmailSent && (
          <div className="ml-4 flex-shrink-0">
            <Button
              onClick={handleSendFirstEmail}
              disabled={isSetupInProgress}
              color="green"
              className="whitespace-nowrap"
            >
              {isSetupInProgress ? 'Sending...' : 'Send Brief'}
            </Button>
          </div>
        )}
        {isExploding && (
          <div className="absolute left-1/2 -translate-x-1/2">
            <ConfettiExplosion
              force={0.8}
              duration={3000}
              particleCount={250}
              width={1600}
            />
          </div>
        )}
      </div>
    </div>
  </div>
)}

      <div className="space-y-6 mt-8">
        {/* Business Information */}
        <section className="bg-white dark:bg-gray-900 rounded-lg shadow p-6 relative">
          { !hasOrganization && (
            <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10 rounded-lg flex flex-col items-center justify-center">
              <CreateOrganization />
            </div>
          )}
          <div className="flex justify-between items-center mb-4">
            <Heading level={2} className="text-xl font-semibold">Company</Heading>
            <button
              onClick={handleAutoPopulateCompanyInfo}
              disabled={isCompanyAutoPopulating || !hasOrganization}
              className="p-2 rounded-full bg-white dark:bg-gray-900 shadow-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
              title="Auto-populate company info"
            >
              {isCompanyAutoPopulating ? (
                <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500" />
              ) : companyAutoPopulateStatus === 'success' ? (
                <CheckIcon className="h-5 w-5 text-green-500" />
              ) : companyAutoPopulateStatus === 'error' ? (
                <NoSymbolIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <SparklesIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          </div>
          <p className="text-sm text-gray-500 mb-4">Enter your company details</p>
          {/* Company Name field is now hidden */}
          <div className="mb-6 relative">
            <label htmlFor="details" className="block text-sm font-medium text-gray-700 mb-1">
              Company Description
            </label>
            <Textarea 
              id="details" 
              value={businessData.details}
              onChange={handleInputChange}
              placeholder="Tell us more about your business, strategy, and team structure" 
              className="w-full h-32"
              disabled={!hasOrganization}
            />
          </div>
          <AnimatePresence>
            {isBusinessDataModified && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="flex items-center overflow-hidden"
              >
                <Button 
                  color="dark" 
                  onClick={handleSaveSettings} 
                  disabled={isSaving}
                  className="relative"
                >
                  {isSaving ? 'Saving...' : 'Save'}
                  {saveSuccess && (
                    <CheckIcon className="w-5 w-5 text-green-500 absolute -right-6" />
                  )}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <Heading level={2} className="text-xl font-semibold">Role</Heading>
              <button
                onClick={handleAutoPopulateJobInfo}
                disabled={isJobAutoPopulating || !hasOrganization}
                className="p-2 rounded-full bg-white dark:bg-gray-900 shadow-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
                title="Auto-populate job info"
              >
                {isJobAutoPopulating ? (
                  <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500" />
                ) : jobAutoPopulateStatus === 'success' ? (
                  <CheckIcon className="h-5 w-5 text-green-500" />
                ) : jobAutoPopulateStatus === 'error' ? (
                  <NoSymbolIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <SparklesIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            <p className="text-sm text-gray-500 mb-4">Enter your role details</p>
            <div className="mb-6">
              <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">Location</label>
              <AutoCompleteInput 
                onLocationSelect={handleLocationSelect} 
                initialLocation={jobData.location} // Pass the initial location
              />
            </div>
            <div className="mb-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Job Title</label>
              <Input
                id="title"
                value={jobData.title}
                onChange={handleJobInputChange}
                placeholder="Enter your job title"
                disabled={!hasOrganization}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">Job Description</label>
              <Textarea
                id="description"
                value={jobData.description}
                onChange={handleJobInputChange}
                placeholder="Describe your role and day-to-day responsibilities"
                className="w-full h-32"
                disabled={!hasOrganization}
              />
            </div>
            <AnimatePresence>
              {isJobDataModified && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center overflow-hidden"
                >
                  <Button 
                    color="dark" 
                    onClick={handleSaveJobSettings} 
                    disabled={isJobSaving}
                    className="relative"
                  >
                    {isJobSaving ? 'Saving...' : 'Save'}
                    {jobSaveSuccess && (
                      <CheckIcon className="w-5 h-5 text-green-500 absolute -right-6" />
                    )}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </section>

        {/* Data Sources */}
        <section
          id="data-sources"
          ref={dataSourcesRef}
          className="bg-white dark:bg-gray-900 rounded-lg shadow p-6"
        >
          <Heading level={2} className="text-xl font-semibold mb-4">Data Sources</Heading>
          <p className="text-sm text-gray-500 mb-4">Manage your connected data sources</p>
          <Table className="bg-white dark:bg-gray-900">
            <TableHead>
              <TableRow>
                <TableHeader>Source</TableHeader>
                <TableHeader>Description</TableHeader>
                <TableHeader>Status</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="flex items-center">
                    <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/google.png" alt="Google" width={24} height={24} className="mr-2" />
                    Google
                    <Tooltip text="Connection is required for daily briefs">
                      <Badge color="blue" className="ml-2 cursor-help">Required</Badge>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell>Gmail, Google Calendar, Google Analytics</TableCell>
                <TableCell className="flex items-center space-x-2">
                  <Button 
                    onClick={isGoogleConnected ? handleDisconnectGoogle : handleConnectGoogle} 
                    color={isGoogleConnected ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {isGoogleConnected ? 'Disconnect' : 'Connect'}
                  </Button>
                  {isGoogleConnected && (
                    <button
                      onClick={handleOpenGoogleSettings}
                      className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                      title="Google Settings"
                    >
                      <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="flex items-center">
                    <img 
                      src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/slack.png" 
                      alt="Slack" 
                      width={22} 
                      height={22} 
                      className="mr-2" 
                  />
                    Slack
                  </div>
                </TableCell>
                <TableCell>Channels, Threads, Messages</TableCell>
                <TableCell className="flex items-center space-x-2">
                  <Button 
                    onClick={isSlackConnected ? handleDisconnectSlack : handleConnectSlack} 
                    color={isSlackConnected ? "red" : "dark"}
                  >
                    {isSlackConnected ? 'Disconnect' : 'Connect'}
                  </Button>
                  {isSlackConnected && (
                    <button
                      onClick={() => setIsSlackSettingsOpen(true)}
                      className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                      title="Slack Settings"
                    >
                      <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="flex items-center">
                    <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/shopify.png" alt="Shopify" width={22} height={22} className="mr-2" />
                    Shopify
                  </div>
                </TableCell>
                <TableCell>Orders, Customers, Analytics</TableCell>
                <TableCell className="flex items-center space-x-2">
                  <Button 
                    onClick={isShopifyConnected ? handleDisconnectShopify : handleShopifyConnect} 
                    color={isShopifyConnected ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {isShopifyConnected ? 'Disconnect' : 'Connect'}
                  </Button>
                  {isShopifyConnected && (
                    <button
                      onClick={handleShopifyConnect}
                      className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                      title="Shopify Settings"
                    >
                      <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="flex items-center">
                    <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/xero.png" alt="Xero" width={24} height={24} className="mr-2" />
                    Xero
                  </div>
                </TableCell>
                <TableCell>Invoices, Bills, Transactions</TableCell>
                <TableCell>
                  <Button 
                    onClick={isXeroConnected ? handleDisconnectXero : handleConnectXero} 
                    color={isXeroConnected ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {isXeroConnected ? 'Disconnect' : 'Connect'}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="flex items-center">
                    <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/hubspot.png" alt="Hubspot" width={24} height={24} className="mr-2" />
                    Hubspot
                  </div>
                </TableCell>
                <TableCell>Deals, Contacts, Companies</TableCell>
                <TableCell className="flex items-center space-x-2">
                  <Button 
                    onClick={isHubspotConnected ? handleDisconnectHubspot : handleHubspotConnect} 
                    color={isHubspotConnected ? "red" : "dark"}
                    disabled={isSetupInProgress}
                  >
                    {isHubspotConnected ? 'Disconnect' : 'Connect'}
                  </Button>
                  {isHubspotConnected && (
                  <button
                    onClick={handleHubspotSettings}
                    className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                    title="Hubspot Settings"
                  >
                    <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                  </button>
                )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>

        {/* Daily Reports */}
        <section className="bg-white dark:bg-gray-900 rounded-lg shadow p-6 relative">
          {!isGoogleConnected && (
            <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10 rounded-lg" />
          )}
          <div className="flex justify-between items-center mb-4">
            <Heading level={2} className="text-xl font-semibold">Daily Briefs</Heading>
          </div>
          {isGoogleConnected && dailyReports.email ? (
            <div className="flex bg-gray-100 rounded-lg p-4 mb-4">
              <svg className="w-5 h-5 text-gray-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <p className="ml-3 text-sm text-gray-700">
                <span className="font-medium"></span> {user?.primaryEmailAddress?.emailAddress} will receive a daily brief every day at 7:00AM ET
              </p>
            </div>
          ) : isGoogleConnected && !dailyReports.email && (
            <div className="flex bg-red-100 rounded-lg p-4 mb-4">
              <svg className="w-5 h-5 text-red-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <p className="ml-3 text-sm text-red-700">
                <span className="font-medium">Oops!</span> Please turn on a channel to activate your daily briefs
              </p>
            </div>
          )}
          
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {[
              { method: 'email', icon: EnvelopeIcon, label: 'Email', comingSoon: false },
              { method: 'video', icon: VideoCameraIcon, label: 'Video', comingSoon: true },
              { method: 'audio', icon: SpeakerWaveIcon, label: 'Podcast', comingSoon: true },
            ].map(({ method, icon: Icon, label, comingSoon }) => (
              <div 
                key={method} 
                className={clsx(
                  "p-4 rounded-lg relative",
                  isSetupInProgress || !isGoogleConnected
                    ? "bg-gray-100 dark:bg-gray-800 cursor-not-allowed opacity-60" 
                    : comingSoon
                      ? "bg-gray-100 dark:bg-gray-800 cursor-not-allowed"
                      : `cursor-pointer transition-all duration-200 hover:shadow-md ${
                          dailyReports[method as keyof typeof dailyReports]
                            ? 'bg-blue-100 dark:bg-blue-900 shadow-md hover:bg-blue-200 dark:hover:bg-blue-800'
                            : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700'
                        }`
                )}
                onClick={() => !comingSoon && !isSetupInProgress && isGoogleConnected && handleDailyReportToggle(method as keyof typeof dailyReports)}
              >
                <div className="flex flex-col items-center h-full justify-between">
                  <div className="flex flex-col items-center">
                    <Icon className={clsx(
                      "w-12 h-12 mb-2",
                      !isGoogleConnected
                        ? "text-gray-400 dark:text-gray-500"
                        : comingSoon
                          ? "text-gray-400 dark:text-gray-500"
                          : dailyReports[method as keyof typeof dailyReports]
                            ? "text-blue-600 dark:text-blue-400"
                            : "text-gray-600 dark:text-gray-400"
                    )} />
                    <p className={clsx(
                      "font-medium text-center",
                      !isGoogleConnected
                        ? "text-gray-400 dark:text-gray-500"
                        : comingSoon
                          ? "text-gray-400 dark:text-gray-500"
                          : dailyReports[method as keyof typeof dailyReports]
                            ? "text-blue-700 dark:text-blue-300"
                            : "text-gray-700 dark:text-gray-300"
                    )}>
                      {label}
                    </p>
                  </div>
                  {isGoogleConnected && !isSetupInProgress && (
                    <p className={clsx(
                      "text-sm mt-2 text-center",
                      comingSoon
                        ? "text-gray-400 dark:text-gray-500"
                        : dailyReports[method as keyof typeof dailyReports]
                          ? "text-blue-600 dark:text-blue-400"
                          : "text-gray-500 dark:text-gray-400"
                    )}>
                      {comingSoon ? 'Coming Soon' : (dailyReports[method as keyof typeof dailyReports] ? 'On' : 'Off')}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Test Email Button for specific users */}
        <TestEmailSection 
          userId={user?.id || ''}
          isDevelopment={isDevelopment}
        />

      </div>

      {/* Hubspot Connect Alert */}
      <HubspotAlert 
        isOpen={isHubspotAlertOpen}
        onClose={() => setIsHubspotAlertOpen(false)}
        mode="connect"
        hubspotStep={hubspotStep}
        setHubspotStep={setHubspotStep}
        isHubspotConnected={isHubspotConnected}
        hubspotApiKey={hubspotApiKey}
        setHubspotApiKey={setHubspotApiKey}
        isHubspotInputFocused={isHubspotInputFocused}
        setIsHubspotInputFocused={setIsHubspotInputFocused}
        isLoadingPipelines={isLoadingPipelines}
        hubspotPipelines={hubspotPipelines}
        selectedPipelineId={selectedPipelineId}
        setSelectedPipelineId={setSelectedPipelineId}
        selectedStages={selectedStages}
        toggleStageVisibility={toggleStageVisibility}
        handleConfirmHubspotConnect={handleConfirmHubspotConnect}
      />

      {/* Shopify Connect/Update Alert */}
      <ShopifyAlert 
        isOpen={isShopifyAlertOpen}
        onClose={() => setIsShopifyAlertOpen(false)}
        isShopifyConnected={isShopifyConnected}
        shopifyApiKey={shopifyApiKey}
        setShopifyApiKey={setShopifyApiKey}
        shopifySubdomain={shopifySubdomain}
        setShopifySubdomain={setShopifySubdomain}
        isShopifyInputFocused={isShopifyInputFocused}
        setIsShopifyInputFocused={setIsShopifyInputFocused}
        isShopifySubdomainFocused={isShopifySubdomainFocused}
        setIsShopifySubdomainFocused={setIsShopifySubdomainFocused}
        handleConfirmShopifyConnect={handleConfirmShopifyConnect}
      />

      {/* Google Settings Alert */}
      <GoogleAlert 
        isOpen={isGoogleSettingsAlertOpen}
        onClose={() => setIsGoogleSettingsAlertOpen(false)}
        isLoadingProperties={isLoadingProperties}
        googleAnalyticsProperties={googleAnalyticsProperties}
        googleAnalyticsPropertyId={googleAnalyticsPropertyId}
        setGoogleAnalyticsPropertyId={setGoogleAnalyticsPropertyId}
        handleOpenGoogleSettings={handleOpenGoogleSettings}
      />

      {/* Slack Settings Alert */}
      <SlackAlert 
        isOpen={isSlackSettingsOpen}
        onClose={() => setIsSlackSettingsOpen(false)}
        slackChannels={slackChannels}
        handleChannelToggle={handleChannelToggle}
      />

      {/* Hubspot Settings Alert */}
      <HubspotAlert 
        isOpen={isHubspotSettingsOpen}
        onClose={() => setIsHubspotSettingsOpen(false)}
        mode="settings"
        isLoadingPipelines={isLoadingPipelines}
        hubspotPipelines={hubspotPipelines}
        selectedPipelineId={selectedPipelineId}
        setSelectedPipelineId={setSelectedPipelineId}
        selectedStages={selectedStages}
        toggleStageVisibility={toggleStageVisibility}
        handleSaveHubspotSettings={handleSaveHubspotSettings}
      />

        {/* User Profile and Sign Out at the bottom */}
        <div className="mt-8 border-t pt-8 flex justify-between items-center">
          <div 
            className={clsx(
              "flex min-w-0 items-center gap-3 cursor-pointer rounded-lg p-2 transition-colors duration-200",
              isUserProfileHovered ? "bg-zinc-100 dark:bg-zinc-800" : ""
            )}
            onClick={() => openUserProfile()}
            onMouseEnter={() => setIsUserProfileHovered(true)}
            onMouseLeave={() => setIsUserProfileHovered(false)}
          >
            <UserButton afterSignOutUrl="/" />
            <span className="min-w-0 flex-1">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                {user?.firstName} {user?.lastName}
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                {user?.primaryEmailAddress?.emailAddress}
              </span>
            </span>
          </div>

          <SignOutButton>
            <Button color="red">Sign Out</Button>
          </SignOutButton>
        </div>
    </div>
  );
};

export default SettingsNew;
