import React from 'react';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/alert';
import { Button } from '../../components/button';

interface GoogleProperty {
  name: string;
  displayName: string;
}

interface GoogleAlertProps {
  isOpen: boolean;
  onClose: () => void;
  isLoadingProperties: boolean;
  googleAnalyticsProperties: GoogleProperty[];
  googleAnalyticsPropertyId: string;
  setGoogleAnalyticsPropertyId: (id: string) => void;
  handleOpenGoogleSettings: () => Promise<void>;
}

export const GoogleAlert: React.FC<GoogleAlertProps> = ({
  isOpen,
  onClose,
  isLoadingProperties,
  googleAnalyticsProperties,
  googleAnalyticsPropertyId,
  setGoogleAnalyticsPropertyId,
  handleOpenGoogleSettings,
}) => {
  return (
    <Alert open={isOpen} onClose={onClose}>
      <AlertTitle>Manage Google Analytics</AlertTitle>
      <AlertDescription>
        <p className="mb-4">Select the Google Analytics property you want to use.</p>
        {isLoadingProperties ? (
          <p>Loading properties...</p>
        ) : googleAnalyticsProperties.length > 0 ? (
          <div className="relative">
            <select
              value={googleAnalyticsPropertyId}
              onChange={(e) => setGoogleAnalyticsPropertyId(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              {googleAnalyticsProperties.map((property) => (
                <option key={property.name} value={property.name}>
                  {`${property.displayName} (${property.name.split('/').pop()})`}
                </option>
              ))}
              <option value="none">None</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
        ) : (
          <div className="text-red-600">
            No Google Analytics properties found. Please make sure you have access to Google Analytics properties and try again.
          </div>
        )}
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={async () => {
            try {
              await handleOpenGoogleSettings();
              onClose();
            } catch (error) {
              alert('Failed to save Google settings. Please try again.');
            }
          }} 
          disabled={isLoadingProperties}
        >
          Save
        </Button>
      </AlertActions>
    </Alert>
  );
}; 